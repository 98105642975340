import { JsonDecoder } from "ts.data.json";
import { DriverShiftStatus } from "../driver_shift_status";
import { DeliveryStatus } from "../delivery_status";

import DispatchApiClient from "src/admin/dispatch/dispatch_api_client";
import { Temporal } from "@js-temporal/polyfill";
import Modal from "src/admin/modal";

type ApiClientInterface = DispatchApiClient;

class Selection {
  id: string;
  description: string;
  quantity: string;

  constructor(id: string, description: string, quantity: string) {
    this.id = id;
    this.description = description;
    this.quantity = quantity;
  }

  static decoder = JsonDecoder.object<Selection>({
    id: JsonDecoder.string,
    description: JsonDecoder.string,
    quantity: JsonDecoder.string,
  }, "Selection");
}

class SelectionGroup {
  id: string;
  description: string;
  selections: Selection[];

  constructor(id: string, description: string, selections: Selection[]) {
    this.id = id
    this.description = description;
    this.selections = selections;
  }

  static decoder = JsonDecoder.object<SelectionGroup>({
    id: JsonDecoder.string,
    description: JsonDecoder.string,
    selections: JsonDecoder.array(Selection.decoder, "selections[]"),
  }, "SelectionGroup");
}

class Item {
  id: string;
  quantity: number;
  description: string;
  selectionGroups: SelectionGroup[];

  constructor(
    id: string,
    quantity: number,
    description: string,
    selectionGroups: SelectionGroup[]) {
    this.id = id;
    this.quantity = quantity;
    this.description = description;
    this.selectionGroups = selectionGroups;
  }

  static decoder = JsonDecoder.object<Item>({
    id: JsonDecoder.string,
    quantity: JsonDecoder.number,
    description: JsonDecoder.string,
    selectionGroups: JsonDecoder.array(SelectionGroup.decoder, "selectionGroups[]"),
  }, "Item");
}

class Delivery {
  id: number;
  orderId: number;
  storeName: string;
  storePhone: string;
  storeAddress: string;
  storeId: number;
  name: string;
  identifier: string;
  phone: string;
  status: string;
  deliveryStatus: DeliveryStatus;
  pickupSequence: number;
  pickupTime: string;
  pickedUpAt: string | null;
  deliveryBusinessName: string | null;
  deliveryAddress: string;
  suburb: string;
  dropoffLatitude: number;
  dropoffLongitude: number;
  dropoffSequence: number;
  deliveryTime: string;
  deliveryInstructions: string | null;
  delaySent: number | null;
  dashNote: string;
  notifications: string[];
  requiresAgeVerification: boolean;
  driverId: number | null;
  groupId: number | null;
  acknowledgedByDriver: boolean;
  operationsException: boolean;
  latestAssignmentTime: string | null;
  pickupTimeIso8601: Date;
  dropoffTimeIso8601: Date;
  makeNowFeature: boolean;
  allowGrouping: boolean;
  allowDeallocation: boolean;
  allowAllocation: boolean;
  allowPrepDelay: boolean;
  prepDelay: number;
  allowDelayMessage: boolean;
  originalPickupTime: string;
  originalDeliveryTime: string;
  deliveryMinutes: number;

  constructor(
    id: number,
    orderId: number,
    storeName: string,
    storePhone: string,
    storeId: number,
    storeAddress: string,
    name: string,
    identifier: string,
    phone: string,
    status: string,
    deliveryStatus: DeliveryStatus,
    pickupSequence: number,
    pickupTime: string,
    pickedUpAt: string | null,
    deliveryBusinessName: string | null,
    deliveryAddress: string,
    suburb: string,
    dropoffLatitude: number,
    dropoffLongitude: number,
    dropoffSequence: number,
    deliveryTime: string,
    deliveryInstructions: string | null,
    delaySent: number | null,
    dashNote: string,
    notifications: string[],
    requiresAgeVerification: boolean,
    driverId: number | null,
    groupId: number | null,
    acknowledgedByDriver: boolean,
    operationsException: boolean,
    latestAssignmentTime: string | null,
    pickupTimeIso8601: Date,
    dropoffTimeIso8601: Date,
    makeNowFeature: boolean,
    allowGrouping: boolean,
    allowDeallocation: boolean,
    allowAllocation: boolean,
    allowPrepDelay: boolean,
    prepDelay: number,
    allowDelayMessage: boolean,
    originalPickupTime: string,
    originalDeliveryTime: string,
    deliveryMinutes: number,
  ) {
    this.id = id;
    this.orderId = orderId;
    this.storeName = storeName;
    this.storePhone = storePhone;
    this.storeAddress = storeAddress;
    this.storeId = storeId;
    this.name = name;
    this.identifier = identifier;
    this.phone = phone;
    this.status = status;
    this.deliveryStatus = deliveryStatus;
    this.pickupSequence = pickupSequence;
    this.pickupTime = pickupTime;
    this.pickedUpAt = pickedUpAt;
    this.deliveryBusinessName = deliveryBusinessName;
    this.deliveryAddress = deliveryAddress;
    this.suburb = suburb;
    this.dropoffLatitude = dropoffLatitude;
    this.dropoffLongitude = dropoffLongitude;
    this.dropoffSequence = dropoffSequence;
    this.deliveryTime = deliveryTime;
    this.deliveryInstructions = deliveryInstructions;
    this.delaySent = delaySent;
    this.dashNote = dashNote;
    this.notifications = notifications;
    this.requiresAgeVerification = requiresAgeVerification;
    this.acknowledgedByDriver = acknowledgedByDriver;
    this.operationsException = operationsException;
    this.driverId = driverId;
    this.groupId = groupId;
    this.latestAssignmentTime = latestAssignmentTime;
    this.pickupTimeIso8601 = pickupTimeIso8601;
    this.dropoffTimeIso8601 = dropoffTimeIso8601;
    this.makeNowFeature = makeNowFeature;
    this.allowGrouping = allowGrouping;
    this.allowDeallocation = allowDeallocation;
    this.allowAllocation = allowAllocation;
    this.allowPrepDelay = allowPrepDelay;
    this.prepDelay = prepDelay;
    this.allowDelayMessage = allowDelayMessage;
    this.originalPickupTime = originalPickupTime;
    this.originalDeliveryTime = originalDeliveryTime;
    this.deliveryMinutes = deliveryMinutes;
  }

  static decoder = JsonDecoder.object<Delivery>({
    id: JsonDecoder.number,
    orderId: JsonDecoder.number,
    storeName: JsonDecoder.string,
    storePhone: JsonDecoder.string,
    storeAddress: JsonDecoder.string,
    storeId: JsonDecoder.number,
    name: JsonDecoder.string,
    identifier: JsonDecoder.string,
    phone: JsonDecoder.string,
    status: JsonDecoder.string,
    deliveryStatus: JsonDecoder.enumeration<DeliveryStatus>(DeliveryStatus, 'DeliveryStatus'),
    pickupSequence: JsonDecoder.number,
    pickupTime: JsonDecoder.string,
    pickedUpAt: JsonDecoder.nullable(JsonDecoder.string),
    deliveryBusinessName: JsonDecoder.nullable(JsonDecoder.string),
    deliveryAddress: JsonDecoder.string,
    suburb: JsonDecoder.string,
    dropoffLatitude: JsonDecoder.number,
    dropoffLongitude: JsonDecoder.number,
    dropoffSequence: JsonDecoder.number,
    deliveryTime: JsonDecoder.string,
    deliveryInstructions: JsonDecoder.nullable(JsonDecoder.string),
    delaySent: JsonDecoder.nullable(JsonDecoder.number),
    dashNote: JsonDecoder.failover("", JsonDecoder.string),
    notifications: JsonDecoder.array(JsonDecoder.string, 'notifications[]'),
    requiresAgeVerification: JsonDecoder.boolean,
    acknowledgedByDriver: JsonDecoder.boolean,
    operationsException: JsonDecoder.boolean,
    driverId: JsonDecoder.nullable(JsonDecoder.number),
    groupId: JsonDecoder.nullable(JsonDecoder.number),
    latestAssignmentTime: JsonDecoder.nullable(JsonDecoder.string),
    pickupTimeIso8601: JsonDecoder.string.map(stringDate => new Date(stringDate)),
    dropoffTimeIso8601: JsonDecoder.string.map(stringDate => new Date(stringDate)),
    makeNowFeature: JsonDecoder.boolean,
    allowGrouping: JsonDecoder.boolean,
    allowDeallocation: JsonDecoder.boolean,
    allowAllocation: JsonDecoder.boolean,
    allowPrepDelay: JsonDecoder.boolean,
    prepDelay: JsonDecoder.number,
    allowDelayMessage: JsonDecoder.boolean,
    originalPickupTime: JsonDecoder.string,
    originalDeliveryTime: JsonDecoder.string,
    deliveryMinutes: JsonDecoder.number,
  }, "Delivery");
}

const PlainTimeDecoder: JsonDecoder.Decoder<Temporal.PlainTime> = JsonDecoder.string.map((str) => {
  return Temporal.PlainTime.from(str, { overflow: 'reject' });
});

const ZonedDateTimeDecoder: JsonDecoder.Decoder<Temporal.ZonedDateTime> = JsonDecoder.string.map((str) => {
  return Temporal.Instant.from(str).toZonedDateTimeISO("Pacific/Auckland");
});

class DeliveryGroup {
  groupNumber: number;
  id: number;
  locked: boolean;

  constructor(groupNumber: number, id: number, locked: boolean) {
    this.groupNumber = groupNumber;
    this.id = id;
    this.locked = locked
  }

  static decoder = JsonDecoder.object<DeliveryGroup>({
    groupNumber: JsonDecoder.number,
    id: JsonDecoder.number,
    locked: JsonDecoder.boolean,
  }, "DeliveryGroup");
}

class DriverShiftTimes {
  startTime: Temporal.PlainTime;
  endTime: Temporal.PlainTime;

  constructor(startTime: Temporal.PlainTime, endTime: Temporal.PlainTime) {
    this.startTime = startTime;
    this.endTime = endTime;
  }

  static decoder = JsonDecoder.object<DriverShiftTimes>({
    startTime: PlainTimeDecoder,
    endTime: PlainTimeDecoder
  }, "DriverShiftTimes");
}

class Driver {
  id: number;
  areaId: number;
  status: DriverShiftStatus;
  pubnubUuid: string;
  name: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  vehicleType: string;
  tenPmCurfew: boolean;
  rating: number | null;
  shiftId: number | null;
  dashNote: string;
  startTime?: Temporal.ZonedDateTime;
  endTime?: Temporal.ZonedDateTime;
  upcomingShiftTimes: DriverShiftTimes[];
  alcoholDeliveryAllowed: boolean;
  internalNote: string;
  requestedDeliveriesAt: string | null;
  requestedDeliveriesLocation: string;
  treatAsInexperienced: boolean;
  restrictedStoreIds: number[];

  constructor(
    id: number,
    areaId: number,
    status: DriverShiftStatus,
    pubnubUuid: string,
    name: string,
    firstName: string,
    lastName: string,
    mobileNumber: string,
    vehicleType: string,
    tenPmCurfew: boolean,
    rating: number | null,
    shiftId: number | null,
    dashNote: string,
    startTime: Temporal.ZonedDateTime,
    endTime: Temporal.ZonedDateTime,
    upcomingShiftTimes: DriverShiftTimes[],
    alcoholDeliveryAllowed: boolean,
    internalNote: string,
    requestedDeliveriesAt: string | null,
    requestedDeliveriesLocation: string | null,
    treatAsInexperienced: boolean,
    restrictedStoreIds: number[],
  ) {
    this.id = id;
    this.areaId = areaId;
    this.status = status;
    this.pubnubUuid = pubnubUuid;
    this.name = name;
    this.firstName = firstName;
    this.lastName = lastName;
    this.mobileNumber = mobileNumber;
    this.vehicleType = vehicleType;
    this.tenPmCurfew = tenPmCurfew;
    this.rating = rating;
    this.shiftId = shiftId;
    this.dashNote = dashNote;
    this.startTime = startTime;
    this.endTime = endTime;
    this.upcomingShiftTimes = upcomingShiftTimes;
    this.alcoholDeliveryAllowed = alcoholDeliveryAllowed;
    this.internalNote = internalNote;
    this.requestedDeliveriesAt = requestedDeliveriesAt;
    this.requestedDeliveriesLocation = requestedDeliveriesLocation || "";
    this.treatAsInexperienced = treatAsInexperienced;
    this.restrictedStoreIds = restrictedStoreIds;
  }

  active(): boolean {
    return (this.status == DriverShiftStatus.Active);
  }

  static decoder = JsonDecoder.object<Driver>({
    id: JsonDecoder.number,
    areaId: JsonDecoder.number,
    status: JsonDecoder.enumeration<DriverShiftStatus>(DriverShiftStatus, 'DriverShiftStatus'),
    pubnubUuid: JsonDecoder.string,
    name: JsonDecoder.string,
    firstName: JsonDecoder.string,
    lastName: JsonDecoder.string,
    mobileNumber: JsonDecoder.string,
    vehicleType: JsonDecoder.string,
    tenPmCurfew: JsonDecoder.boolean,
    rating: JsonDecoder.nullable(JsonDecoder.number),
    shiftId: JsonDecoder.nullable(JsonDecoder.number),
    dashNote: JsonDecoder.string,
    startTime: JsonDecoder.optional(ZonedDateTimeDecoder),
    endTime: JsonDecoder.optional(ZonedDateTimeDecoder),
    upcomingShiftTimes: JsonDecoder.array<DriverShiftTimes>(DriverShiftTimes.decoder, "upcomingShiftTimes[]"),
    alcoholDeliveryAllowed: JsonDecoder.boolean,
    internalNote: JsonDecoder.failover("", JsonDecoder.string),
    requestedDeliveriesAt: JsonDecoder.nullable(JsonDecoder.string),
    requestedDeliveriesLocation: JsonDecoder.failover("", JsonDecoder.string),
    active: JsonDecoder.succeed,
    treatAsInexperienced: JsonDecoder.boolean,
    restrictedStoreIds: JsonDecoder.array(JsonDecoder.number, "restrictedStoreIds[]"),
  }, "Driver");
}

class TimeSlot {
  time: string;
  unformattedTime: string;
  rating: string;
  colour: string;
  panicked: boolean;
  isOperatingTime: boolean;

  constructor(t: string, unformattedTime: string, rating: string, colour: string, panicked: boolean, isOperatingTime: boolean) {
    this.time = t;
    this.unformattedTime = unformattedTime;
    this.rating = rating;
    this.colour = colour;
    this.panicked = panicked;
    this.isOperatingTime = isOperatingTime;
  }
}

type DashboardParams = {
  areaId: number;
  deliveries: Delivery[];
  drivers: Driver[];
  groups: DeliveryGroup[];
  areaOpen: Date;
  areaClosed: Date;
  nextAvailablePickupTime: Date;
  tuning: number;
}

class Dashboard {
  readonly areaId: number;
  readonly deliveries: Delivery[];
  readonly areaOpen: Date;
  readonly areaClosed: Date;
  readonly nextAvailablePickupTime: Date;
  readonly tuning: number;
  readonly drivers: Driver[];
  readonly groups: DeliveryGroup[];

  private _driversActive?: Driver[];
  private _driversRostered?: Driver[];
  private _driversRosteredToStartSoon?: Driver[];
  private _driversBusyPickingUp?: Driver[];
  private _driversBusyDroppingOff?: Driver[];
  private _driversWithoutDeliveries?: Driver[];
  private _driversAwaitingDeliveries?: Driver[];
  private _driversIdle?: Driver[];
  private _unallocatedCurrent?: Delivery[];
  private _unallocatedFuture?: Delivery[];
  private _deliveryDriverIDs?: (number | null)[];
  private _deliveriesByDriver?: Map<number, Delivery[]>;
  private _deliveriesByGroup?: Map<number, Delivery[]>;

  constructor({ areaId, deliveries, drivers, groups, areaOpen, areaClosed, nextAvailablePickupTime, tuning }: DashboardParams) {
    this.areaId = areaId;
    this.deliveries = deliveries;
    this.drivers = drivers;
    this.groups = groups;
    this.areaOpen = areaOpen;
    this.areaClosed = areaClosed;
    if (this.areaClosed <= this.areaOpen) {
      this.areaClosed.setDate(this.areaClosed.getDate() + 1);
    }
    this.nextAvailablePickupTime = nextAvailablePickupTime;
    this.tuning = tuning;
  }

  static emptyDashboard() {
    const areaOpen = new Date();
    const areaClosed = new Date(areaOpen);
    areaOpen.setHours(0, 0, 0)
    areaClosed.setHours(24, 0, 0)
    return new Dashboard({ areaId: 0, deliveries: [], drivers: [], groups: [], areaOpen, areaClosed, nextAvailablePickupTime: new Date(), tuning: 100 })
  }

  withChanges(args: Partial<DashboardParams>) {
    return new Dashboard(
      { ...this, ...args }
    )
  }

  driversActive(): Driver[] {
    return this._driversActive ??=
      this.drivers.filter(d => d.status == DriverShiftStatus.Active);
  }

  driversRostered(): Driver[] {
    return this._driversRostered ??=
      this.drivers.filter(d => d.status == DriverShiftStatus.RosteredButInactive && d.startTime !== undefined).sort((a, b) => {
        // The if statement below allows us to use the compare function on the Temporal object as this required
        // Will otherwise throw string type error
        if (a.startTime === undefined || b.startTime === undefined) { return 1; }
        return (Temporal.ZonedDateTime.compare(a.startTime, b.startTime));
      })
  }

  driversRosteredToStartSoon(): Driver[] {
    return this._driversRosteredToStartSoon ??=
      this.drivers.filter(d => d.status == DriverShiftStatus.RosteredToStartSoon && d.startTime !== undefined).sort((a, b) => {
        // The if statement below allows us to use the compare function on the Temporal object as this required
        // Will otherwise throw string type error
        if (a.startTime === undefined || b.startTime === undefined) { return 1; }
        return (Temporal.ZonedDateTime.compare(a.startTime, b.startTime));
      })
  }

  driversBusyPickingUp(): Driver[] {
    return this._driversBusyPickingUp ??=
      this.driversActive().filter(d => this.deliveryDriverIDs().includes(d.id) && this.deliveriesForDriver(d.id).some(o => o.pickedUpAt == null));
  }

  driversBusyDroppingOff(): Driver[] {
    return this._driversBusyDroppingOff ??=
      this.driversActive().filter(d => this.deliveryDriverIDs().includes(d.id) && this.deliveriesForDriver(d.id).every(o => o.pickedUpAt != null));
  }

  driversWithoutDeliveries(): Driver[] {
    return this._driversWithoutDeliveries ??=
      this.driversActive().filter(d => !this.deliveryDriverIDs().includes(d.id));
  }

  driversAwaitingDeliveries(): Driver[] {
    return this._driversAwaitingDeliveries ??=
      this.driversWithoutDeliveries().filter(d => d.requestedDeliveriesAt !== null).sort((a, b) => {
        return ((a.requestedDeliveriesAt || "").localeCompare(b.requestedDeliveriesAt || ""));
      });
  }

  driversIdle(): Driver[] {
    return this._driversIdle ??=
      this.driversWithoutDeliveries().filter(d => d.requestedDeliveriesAt === null);
  }

  unallocatedCurrent(): Delivery[] {
    return this._unallocatedCurrent ??=
      this.deliveries.filter(o => o.driverId == null && o.groupId == null && this.isCurrentDelivery(o));
  }

  unallocatedFuture(): Delivery[] {
    return this._unallocatedFuture ??=
      this.deliveries.filter(o => o.driverId == null && o.groupId == null && !this.isCurrentDelivery(o));
  }

  deliveriesForDriver(driverId: number) : Delivery [] {
    return this.deliveriesByDriver().get(driverId) ?? [];
  }

  deliveriesForGroup(groupId: number) : Delivery[] {
    return this.deliveriesByGroup().get(groupId) ?? [];
  }

  deliveriesByDriver(): Map<number, Delivery[]> {
    return this._deliveriesByDriver ??=
      this.deliveries.reduce((results, delivery) => {
        if (delivery.driverId) {
          const deliveriesForDriver = results.get(delivery.driverId) ?? [];
          deliveriesForDriver.push(delivery);
          results.set(delivery.driverId, deliveriesForDriver)
        }
        return results;
      }, new Map());
  }

  deliveriesByGroup(): Map<number, Delivery[]> {
    return this._deliveriesByGroup ??=
      this.deliveries.reduce((results, delivery) => {
        if (delivery.groupId) {
          const deliveriesForGroup = results.get(delivery.groupId) ?? [];
          deliveriesForGroup.push(delivery);
          results.set(delivery.groupId, deliveriesForGroup)
        }
        return results;
      }, new Map());
  }

  availableDriversForDeliveries(deliveries: Delivery[]): Driver[] {
    const storeIds = deliveries.map(delivery => delivery.storeId);
    const anyDeliveryIsAgeRestricted = deliveries.some(delivery => delivery.requiresAgeVerification);

    return this.driversActive().filter(driver => {
      const anyDeliveryIsAllocatedToDriver = deliveries.some(delivery => delivery.driverId == driver.id);
      const anyDeliveryIsDriverRestricted = driver.restrictedStoreIds.some(id => storeIds.includes(id));

      if (anyDeliveryIsAllocatedToDriver || anyDeliveryIsDriverRestricted) {
        return false;
      }

      if (anyDeliveryIsAgeRestricted) {
        return driver.alcoholDeliveryAllowed;
      }

      return true;
    });
  }

  async handleAllocateDeliveryToDriver(deliveryId: number, driverId: number, apiClient: ApiClientInterface) {
    const delivery = this.deliveries.find(d => d.orderId == deliveryId);

    if (delivery == undefined || !delivery.allowAllocation) return false;
    if (!this.availableDriversForDeliveries([delivery]).map(d => d.id).includes(driverId)) return false;
    if (apiClient.isDriverAlreadyDelivering(driverId) && !await this.confirmAddMoreDeliveries()) return false;

    await apiClient.allocateDeliveryToDriver(deliveryId, driverId);
  }

  async handleAllocateGroupToDriver(groupId: number, driverId: number, apiClient: ApiClientInterface) {
    const deliveries = this.deliveriesForGroup(groupId);

    if (deliveries.length == 0 || deliveries.some(d => !d.allowAllocation)) return false;
    if (!this.availableDriversForDeliveries(deliveries).map(d => d.id).includes(driverId)) return false;
    if (apiClient.isDriverAlreadyDelivering(driverId) && !await this.confirmAddMoreDeliveries()) return false;

    await apiClient.allocateGroupToDriver(groupId, driverId);
  }

  async confirmAddMoreDeliveries() {
    return await Modal.show({
      title: "Are you sure?",
      content: "This driver has picked up all their current deliveries and is now delivering. Adding more deliveries to them will prevent them from being able to complete their current deliveries until they have picked up these new deliveries. You should only do this if you are expecting the driver to go pick up these deliveries <b>right now</b>",
      acceptButtonLabel: "Allocate deliveries",
    }).catch(() => undefined);
  }

  heatMap(): TimeSlot[] {
    const timeSlots : TimeSlot[] = []
    const now = new Date();
    const nearestSlot = new Date((Math.round(now.getTime() / 300000) + 1) * 300000);

    this.timeSlotsVisibleOnHeatmap().forEach((slotStart, index) => {
      const rating = this.timeSlotRating(index);
      const panicTime = new Date(Math.max(this.nextAvailablePickupTime.getTime(), nearestSlot.getTime()));
      timeSlots.push(new TimeSlot(
        slotStart.toLocaleTimeString('en', { hour: 'numeric', minute: "numeric", hour12: true, timeZone: "NZ" }),
        slotStart.toISOString(),
        rating.toString().concat("%"),
        this.timeSlotColour(rating),
        panicTime > slotStart,
        (slotStart >= this.areaOpen && slotStart <= this.areaClosed) || index == 0
      ));
    });

    return timeSlots;
  } 

  private timeSlotRating(slotPosition: number) {
    const now = Date.now();
    const firstSlot = Math.round(now / 300000) * 300000;
    const slotStart = firstSlot + (5 * 60 * 1000 * slotPosition);
    const slotEnd = firstSlot + (5 * 60 * 1000 * (slotPosition+1));

    const estimatedDeliveryMinutes = this.deliveries.reduce((prev, delivery) => {
      const deliveryStart = delivery.pickupTimeIso8601.getTime();
      const deliveryEnd = deliveryStart + delivery.deliveryMinutes*60*1000;
      const overlapStart = Math.max(slotStart, deliveryStart);
      const overlapEnd = Math.min(slotEnd, deliveryEnd);
      const overlapMillis = Math.max(overlapEnd - overlapStart, 0);
      return prev + overlapMillis;
    }, 0)/60/1000;

    const optimalMinutes = this.optimalDeliveryMinutesPerTimeSlot()
    if (optimalMinutes == 0 || estimatedDeliveryMinutes == 0){
      return 0.0
    } else {
      return Math.round((estimatedDeliveryMinutes / optimalMinutes) * 100)
    }
  }

  private timeSlotsVisibleOnHeatmap() : Date[]{
    const now = new Date();
    const firstSlot = Math.round(now.getTime() / 300000) * 300000;
    const slots = [];
    for (let i = 0; i < 15; i++) {
      const slotStart = new Date(firstSlot + (5 * 60 * 1000 * i));
      slots.push(slotStart);
    }
    return slots;
  }

  private optimalDeliveryMinutesPerTimeSlot(): number{
    return (this.driversActive().length * 5.0) * (this.tuning / 100);
  }

  private timeSlotColour(rating: number) : string {
    if (rating >= 120) {
      return 'red-time-slot dash-time-slot'
    } else if (rating >= 105){
      return 'orange-time-slot dash-time-slot'
    } else if (rating >= 90){
      return 'yellow-time-slot dash-time-slot'
    } else if (rating >= 75){
      return 'bright-green-time-slot dash-time-slot'
    } else if (rating >= 60){
      return 'muted-green-time-slot dash-time-slot'
    } else if (rating >= 35){
      return 'grey-time-slot dash-time-slot'
    } else {
      return 'clear-time-slot dash-time-slot'
    }
  }

  private deliveryDriverIDs(): (number | null)[] {
    return this._deliveryDriverIDs ??= this.deliveries.map(o => o.driverId);
  }

  private isCurrentDelivery(delivery : Delivery) {
    const now = new Date();
    const pickupTime = new Date(delivery.pickupTimeIso8601);
    const difference = pickupTime.getTime() - now.getTime(); // milliseconds
    return difference / 60000 <= 55 // Current threshold is 55 minutes
  }

  static async decode(data: unknown) : Promise<Dashboard> {
    const dash = await Dashboard.decoder
      .decodeToPromise(data)

    return dash;
  }

  static decoder = JsonDecoder.object<DashboardParams>({
    areaId: JsonDecoder.number,
    deliveries: JsonDecoder.array(Delivery.decoder, "Delivery[]"),
    drivers: JsonDecoder.array(Driver.decoder, "Driver[]"),
    areaOpen: JsonDecoder.string.map(stringDate => new Date(stringDate)),
    areaClosed: JsonDecoder.string.map(stringDate => new Date(stringDate)),
    nextAvailablePickupTime: JsonDecoder.string.map(stringDate => new Date(stringDate)),
    tuning: JsonDecoder.number,
    groups: JsonDecoder.array(DeliveryGroup.decoder, "DeliveryGroup[]"),
  }, "Dashboard").map(d => new Dashboard(d));
}

interface DeliveryTrackingStep {
  readonly seconds: number;
  readonly metres: number;
  readonly points: string;
}

interface DeliveryTracking {
  readonly id: number;
  readonly liveArrivalEstimate?: Date;
  readonly liveLastUpdate: Date;
  readonly routeToDropoff?: DeliveryTrackingStep[];
  readonly routeToPickup?: DeliveryTrackingStep[];
}

interface DriverTrackingUpdate {
  driverId: number;
  latitude: number;
  longitude: number;
  receivedAt: Date;
  deliveryTrackingList: DeliveryTracking[];
}

export { Delivery, Item, DeliveryGroup, Driver, TimeSlot, Dashboard, DeliveryTrackingStep, DeliveryTracking, DriverTrackingUpdate, ApiClientInterface, Selection, SelectionGroup };
