import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["edit", "save", "cancel", "update", "field"];

  declare editTarget: HTMLButtonElement;
  declare saveTarget: HTMLButtonElement;
  declare cancelTarget: HTMLButtonElement;
  declare updateTarget: HTMLButtonElement;

  declare fieldTargets : HTMLElement[];
  declare hasUpdateTarget: boolean;
  declare hasSaveTarget: boolean;

  beginEdit(event: MouseEvent) {
    event.preventDefault();

    const hiddenInputs = Array.from(document.getElementsByTagName("input")).filter((input) => input.type == "hidden" && input.value == "0")

    this.editTarget.classList.add("is-hidden");
    this.cancelTarget.classList.remove("is-hidden");
    this.element.classList.remove("is-editable-outlined");
    this.element.classList.add("is-editing-outlined");

    if (this.hasSaveTarget) {
      this.saveTarget.classList.remove("is-hidden");
    }

    if (this.hasUpdateTarget) {
      this.updateTarget.removeAttribute("disabled");
    }

    hiddenInputs.forEach(t => {
      t.removeAttribute("disabled");
    });

    this.fieldTargets.forEach(t => {
      t.removeAttribute("disabled");

      const removableClass = t.dataset.toggleEditFormRemovableClass;
      const addableClass = t.dataset.toggleEditFormAddableClass;

      if (removableClass !== undefined) {
        t.classList.remove(removableClass);
      }

      if (addableClass !== undefined) {
        t.classList.add(addableClass);
      }
    });
  }

  async saveChanges() {
    if (this.hasSaveTarget) {
      this.saveTarget.classList.add("is-loading");
    }

    if (this.hasUpdateTarget) {
      this.updateTarget.classList.add("is-loading");
    }

    this.cancelTarget.setAttribute("disabled", "disabled");
  }
}
