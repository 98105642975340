import React, { createContext, useContext, useState } from "react";
import { DraggableSyntheticListeners, DraggableAttributes } from "@dnd-kit/core";

import { ApiClientInterface, Dashboard, Delivery, DeliveryGroup, DeliveryTracking, Item, Selection } from "./model";
import { DeliveryGroupSelect, DriverSelect } from "./selects";

interface DeliveryComponentProps {
  defaultExpand: boolean;
  dashboard: Dashboard;
  delivery: Delivery;
  deliveryTracking?: DeliveryTracking;
  deliveringNow: boolean;
  apiClient: ApiClientInterface;
  listeners?: DraggableSyntheticListeners;
  attributes?: DraggableAttributes;
  setActivatorNodeRef?: React.LegacyRef<HTMLDivElement>;
}

const CurrentTimeContext = createContext(new Date(0));

const DeliveryComponent = ({ defaultExpand, dashboard, delivery, deliveryTracking, deliveringNow, apiClient, listeners, attributes, setActivatorNodeRef } : DeliveryComponentProps) => {
  const [expanded, setExpanded] = useState(false);
  const [items, setItems] = useState([] as Item[]);

  const currentTime = useContext(CurrentTimeContext);

  const handleAllocate = async (driverId: number) => {
    // Deallocate the order from the driver
    if (driverId == -1) {
      await apiClient.allocateDeliveryToDriver(delivery.id, driverId)
      return;
    }

    await dashboard.handleAllocateDeliveryToDriver(delivery.id, driverId, apiClient);
  }

  const handleGroup = async (groupId: number) => {
    // if there is no user-selected group, attempt to quick-group
    if (groupId == -2) {
      // do not allow quick-group on allocated orders
      if (delivery.driverId != null) return;
      else {
        // find the first groupId that's not is use, or return -2 if all groups are in use
        const activeGroupIds = Array.from(dashboard.deliveriesByGroup()).map(n => n[0]);
        groupId = dashboard.groups.find(group => !activeGroupIds.includes(group.id))?.id || -2;

        if (groupId == -2) return;
      }
    }

    await apiClient.allocateDeliveryToGroup(delivery.id, groupId);
  }

  const toggleExpanded = async () => {
    setExpanded(!expanded);

    if (items.length === 0) {
      await apiClient.fetchDeliveryDetails(delivery.id).then((refreshedItems) => {
        setItems(refreshedItems);
      });
    }
  }

  const filterDeliveryGroups = (groups: DeliveryGroup[], groupId: number | null) => {
    return groups.filter(function(ele){
      return ele.id != groupId
    });
  }

  const driverSelect = delivery.allowAllocation || delivery.allowDeallocation ?
    <DriverSelect
      drivers={dashboard.availableDriversForDeliveries([delivery])}
      allowUnallocate={delivery.allowDeallocation}
      onConfirm={handleAllocate}
    /> : <></>;

  const groupSelect = delivery.allowGrouping ?
    <DeliveryGroupSelect
      groups={filterDeliveryGroups(dashboard.groups, delivery.groupId)}
      allowUngroup={delivery.groupId != null}
      isUnallocatedOrder={delivery.driverId == null}
      onConfirm={handleGroup}
    /> : <></>;

  const makeNowFeature = delivery.makeNowFeature ? <span className="icon is-small"><i className="fas fa-exclamation"></i></span> : <></>;
  const showAlcohol = delivery.requiresAgeVerification ? <span className="icon is-small" title="Age restricted"><i className="fas fa-wine-glass"></i></span> : <></>;
  const showBusiness = delivery.deliveryBusinessName ? <span className="icon is-small" title="Business address"><i className="fas fa-building"></i></span> : <></>;
  const showDelaySent = delivery.delaySent ? <div className="ml-1"><span className="icon is-small" title="Late notification sent"><i className="fas fa-envelope"></i></span></div> : <></>;

  const expandedDeliverySelects = expanded || (defaultExpand && (delivery.allowAllocation || delivery.allowDeallocation || delivery.allowGrouping)) ?
    <div className="p-2">
      <div className="columns is-variable is-1">
        <div className="column is-half pr-2">
          <div className="mb-1 has-text-weight-medium">Assign</div>
          {driverSelect}
        </div>

        <div className="column is-half pl-2">
          <div className="mb-1 has-text-weight-medium">Group</div>
          {groupSelect}
        </div>
      </div>
    </div> : <></>;

  const expando = expanded
    ? <span className="icon is-small ml-1 order-drop-target"><i className="fas fa-xs fa-chevron-up"></i></span>
    : <span className="icon is-small ml-1 order-drop-target"><i className="fas fa-xs fa-chevron-down"></i></span>;

  const expandoActiveClass = expanded ? "is-active " : "";

  const updateTimeAgo = (lastUpdate?: Date) => {
    if (!lastUpdate) return undefined;

    const elapsedSeconds = (currentTime.getTime() - lastUpdate.getTime())/1000;

    if (elapsedSeconds < 20) {
      return '(updated just now)';
    } else if (elapsedSeconds < 60) {
      return '(updated in the last min)';
    } else if (elapsedSeconds < 120) {
      return '(updated 1 min ago)';
    } else {
      return `(updated ${Math.round(elapsedSeconds / 60)} mins ago)`;
    }
  }

  // eventually this time will replace the Do time in the order header here, but for now we just pass it down to the extended info component
  const liveDropoffTime = deliveryTracking?.liveArrivalEstimate?.toLocaleString("en", { timeStyle: "short" }).replace(' ', '').toLowerCase();
  const liveUpdateTimeAgo = updateTimeAgo(deliveryTracking?.liveLastUpdate);

  const readyForPickupTitle = delivery.status == "Ready For Pick Up" ? "Ready for pickup" : "Pickup time";

  const pickupTime = <>
    <div className="column is-narrow">
      <div title={readyForPickupTitle} className="py-1 pr-2 pickup-time">
        <span className="has-text-grey">
          <span className="icon is-small">
            <i className="far fa-sm fa-clock"></i>
          </span>
          <span>Pu</span>
        </span>
        &nbsp;<strong>{delivery.pickedUpAt?.toUpperCase() || delivery.pickupTime.toUpperCase()}</strong>
      </div>
    </div>
  </>;

  const dropoffTime = delivery.pickedUpAt !== null ?
    <div className="column is-narrow">
      <div title="Drop off time" className="py-1">
        <span className="has-text-grey">
          <span className="icon is-small">
            <i className="far fa-sm fa-clock"></i>
          </span>
          <span>Do</span>
        </span>
        &nbsp;<strong>{delivery.deliveryTime.toUpperCase()}</strong>
      </div>
    </div>
  : <></>;

  const tenMinutesOverdue = () => {
    return pickupOverdueBy(10) || deliveryOverdueBy(10);
  }

  const fifteenMinutesOverdue = () => {
    return pickupOverdueBy(15) || deliveryOverdueBy(15);
  }

  const thirtyMinutesOverdue = () => {
    return pickupOverdueBy(30) || deliveryOverdueBy(30);
  }

  const pickupOverdueBy = (minutes: number) => {
    return !delivery.pickedUpAt && currentTime.getTime() - delivery.pickupTimeIso8601.getTime() > minutes*60*1000;
  }

  const deliveryOverdueBy = (minutes: number) => {
    return currentTime.getTime() - delivery.dropoffTimeIso8601.getTime() > minutes*60*1000;
  }

  const operationsClasses : string[] = [
    delivery.acknowledgedByDriver ? "" : "unacknowledged-by-driver",
    delivery.status == "Paid" ? "unacknowledged-by-restaurant" : "",
    delivery.status == "Ready For Pick Up" ? "ready-for-pickup" : "",
    tenMinutesOverdue() ? "ten-minutes-overdue" : "",
    fifteenMinutesOverdue() ? "fifteen-minutes-overdue" : "",
    thirtyMinutesOverdue() ? "thirty-minutes-overdue" : "",
    delivery.operationsException ? "operations-exception" : "",
  ];

  const primaryOrderTitle : string = (delivery.operationsException && "Operations exception, action required") ||
    (delivery.makeNowFeature && "Using make now feature") ||
    (delivery.status == "Paid" && "Restaurant hasn't acknowledged order") ||
    (thirtyMinutesOverdue() && "Thirty minutes overdue") ||
    (fifteenMinutesOverdue() && "Fifteen minutes overdue") ||
    (tenMinutesOverdue() && "Ten minutes overdue") || ""

  const acknowledgementTitle = delivery.acknowledgedByDriver ? "" : "Driver hasn't accepted order";

  const extendedInfo = expanded ?
    <DeliveryExtendedInfo
      delivery={delivery}
      items={items}
      deliveringNow={deliveringNow}
      liveDropoffTime={liveDropoffTime}
      liveUpdateTimeAgo={liveUpdateTimeAgo}
      apiClient={apiClient}
    /> : <></>;

  const notfications = delivery.notifications.length > 0 ?
    <div className="columns is-flex is-gapless px-2 pb-1 mb-0">
      <div className="column">
        <span className="has-text-grey">
          <span className="icon is-small">
            <i className="far fa-sm fa-sticky-note"></i>
          </span>
          &nbsp;{delivery.notifications.join(', ')}
        </span>
      </div>

      {showDelaySent}
    </div>
    : <></>;

  return (
    <>
      <div className={"order " + expandoActiveClass + operationsClasses.join(" ")}>
        <div title={primaryOrderTitle} className="columns is-gapless is-mobile mb-0 is-flex-wrap-wrap" onClick={toggleExpanded} {...listeners} {...attributes} ref={setActivatorNodeRef}>
          <div title={acknowledgementTitle} className="driver-acknowledgement-indicator"></div>

          <div className="column">
            <div className="py-1 px-2 order-summary">
              <span>
                {makeNowFeature}
                {showAlcohol}
                {delivery.storeName}
              </span>
              <span className="icon is-small px-1">
                <i className="fas fa-xs fa-arrow-right"></i>
              </span>
              <span>
                {showBusiness}
                <b>{delivery.suburb}</b>
              </span>
              {expando}
            </div>

            {notfications}

            <div className="columns is-flex is-gapless pl-2 pr-1 pb-1 mb-0">
              <div className="column columns is-gapless mb-0">
                {pickupTime}
                {dropoffTime}
              </div>

              <MaplinkNode deliveries={[ delivery ]} />
            </div>

            {delivery.dashNote ?
            <div className="px-2 pb-1">
              <span className="dash-note px-1 is-rounded-small has-text-weight-bold overflow-anywhere">{delivery.dashNote}</span>
            </div> : <></>}
          </div>
        </div>

        {expandedDeliverySelects}
        {extendedInfo}
      </div>
    </>
  );
}

const ItemComponent = ({ item }: {
  item: Item;
}) => {
  const selectionGroupNodes = item.selectionGroups.map(group => {
    return <SelectionGroupNode key={group.id} description={group.description} selections={group.selections}/>
  });

  return (
    <div className="py-1">
      <div>
        <b> {item.quantity > 1 ? (<mark>{item.quantity}x</mark>) : (
          <b>{item.quantity}x</b>)} {item.description}</b>
      </div>

      <div className="is-hidden-touch">
        <div>
          {selectionGroupNodes}
        </div>
      </div>
    </div>
  );
}

interface DeliveryExtendedInfoProps {
  delivery: Delivery;
  items?: Item[];
  deliveringNow: boolean;
  liveDropoffTime?: string;
  liveUpdateTimeAgo?: string;
  apiClient: ApiClientInterface;
}

interface DeliveryExtendedInfoState {
  delayTime: string;
  delayReason: string;
  prepDelay: string;
  sending: boolean;
  prepDelaySending: boolean;
  dashNoteInput: string;
}

class DeliveryExtendedInfo extends React.PureComponent<DeliveryExtendedInfoProps, DeliveryExtendedInfoState> {
  state: Readonly<DeliveryExtendedInfoState> = {
    delayTime: '10',
    delayReason: 'delivereasy',
    prepDelay: '10',
    sending: false,
    prepDelaySending: false,
    dashNoteInput: this.props.delivery.dashNote
  };

  changeDelayTime(ev: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      delayTime: ev.target.value
    });
  }

  changeDelayReason(ev: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      delayReason: ev.target.value
    });
  }

  async handleSendText() {
    this.setState({
      sending: true
    });

    await this.props.apiClient.sendDelayMessage(this.props.delivery.id, this.state.delayTime, this.state.delayReason);
  }

  changePrepDelay(ev: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      prepDelay: ev.target.value
    });
  }

  async handleUpdatePrepDelay() {
    this.setState({
      prepDelaySending: true
    });

    await this.props.apiClient.delayPrep(this.props.delivery.id, this.state.prepDelay);
  }

  handleDashNoteInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    this.setState({ dashNoteInput: target.value });
  }

  async handleDashNoteKeypress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.which == 13)
      await this.updateDashNote();
  }

  async updateDashNote() {
    if (this.props.delivery.dashNote != this.state.dashNoteInput)
      await this.props.apiClient.updateDashNote(this.props.delivery.id, this.state.dashNoteInput);
  }

  render() {
    const items = this.props.items != undefined ? this.props.items?.map(item => {
      return (
        <ItemComponent
          key={item.id}
          item={item}
        />
      );
    }) : <></>;

    const deliveryMessage = this.props.delivery.allowDelayMessage ?
      <>
        <div className="p-2">
          <div className="mb-1 is-uppercase">
            Text customer
          </div>

          <div className="columns is-variable is-1">
            <div className="column is-half pr-2">
              <div className="mb-1 has-text-weight-medium">
                Delay
              </div>

              <div className="field has-addons">
                <div className="control is-expanded">
                  <div className="select is-small is-fullwidth is-radiusless">
                    <select className="is-radiusless pl-1" value={this.state.delayTime} onChange={this.changeDelayTime.bind(this)}>
                      <option value='10'>10 minutes</option>
                      <option value='15'>15 minutes</option>
                      <option value='20'>20 minutes</option>
                      <option value='30'>30 minutes</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="column is-half pl-2">
              <div className="mb-1 has-text-weight-medium">
                Reason
              </div>

              <div className="field has-addons">
                <div className="control is-expanded">
                  <div className="select is-small is-fullwidth is-radiusless">
                    <select className="is-radiusless pl-1" value={this.state.delayReason} onChange={this.changeDelayReason.bind(this)}>
                      <option value='delivereasy'>Our Bad</option>
                      <option value='restaurant'>Restaurants Bad</option>
                      <option value='traffic'>Traffic</option>
                    </select>
                  </div>
                </div>
                <div className="control">
                  <button className={`button is-small ${this.state.sending ? "is-loading" : ""}`} disabled={this.state.sending} onClick={this.handleSendText.bind(this)}>
                    <span className="icon">
                      <i className="fas fa-caret-right"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </> : (this.props.delivery.delaySent ? <>
        <div className="p-2"><div className="mb-1 is-uppercase">Text customer</div><div><strong>{this.props.delivery.delaySent + " minute delay sent"}</strong></div></div>
      </> : <></>);

    const delayPrep = this.props.delivery.allowPrepDelay ? <>
      <div className="px-2 pb-2">
        <div className="columns is-variable is-1">
          <div className="column is-half">
            <div className="mb-1 is-uppercase">
              Delay Prep
            </div>

            <div className="field has-addons">
              <div className="control is-expanded">
                <div className="select is-small is-fullwidth is-radiusless">
                  <select className="is-radiusless pl-1" value={this.state.prepDelay} onChange={this.changePrepDelay.bind(this)}>
                    <option value='10'>Delay by 10 minutes</option>
                    <option value='20'>Delay by 20 minutes</option>
                  </select>
                </div>
              </div>
              <div className="control">
                <button className={`button is-small ${this.state.prepDelaySending ? "is-loading" : ""}`} disabled={this.state.prepDelaySending} onClick={this.handleUpdatePrepDelay.bind(this)}>
                  <span className="icon">
                    <i className="fas fa-caret-right"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </> : (this.props.delivery.prepDelay ? <><div className="px-2 pb-2"><div className="mb-1 is-uppercase">Delay Prep</div><div><strong>{"Order prep delayed by " + this.props.delivery.prepDelay + " minutes"}</strong></div></div></> : <></>);

    return (
      <>
        {deliveryMessage}

        <div className="px-2 pt-2">
          <a className="button is-fullwidth is-small mb-3" href={`/admin/orders/${this.props.delivery.orderId}`} target="_blank">
            <span>Open order</span>

            <span className="icon is-small">
              <i className="fas fa-external-link-alt"></i>
            </span>
          </a>

          <div className="mb-2 is-flex is-gap-1">
            <input className="is-flex-grow-1 dash-note-input" type="text" placeholder="Add dash note" defaultValue={this.props.delivery.dashNote} onChange={this.handleDashNoteInputChange.bind(this)} onKeyPress={this.handleDashNoteKeypress.bind(this)} />
            <button className="button is-small" onClick={this.updateDashNote.bind(this)}>Save</button>
          </div>

          <table className="table is-narrow is-fullwidth">
            <tbody>
              <tr>
                <th>#</th>
                <td>
                  <span data-controller="clipboard" data-clipboard-content={`#${this.props.delivery.orderId}`}>
                    <span data-clipboard-target="clipboard" data-tooltip="Copy to clipboard" data-action="click->clipboard#copyToClipboard mouseleave->clipboard#resetTooltip">
                      {this.props.delivery.orderId}
                      <span className="icon">
                        <i className="fas fa-clipboard"></i>
                      </span>
                    </span>
                  </span>
                </td>
                <td></td>
              </tr>

              <tr>
                <th>Rest ph</th>
                <td><a data-number={this.props.delivery.storePhone} data-order-id={this.props.delivery.orderId} data-action="click->phone-widget#call">{this.props.delivery.storePhone}</a></td>
                <td>
                  <a
                    href={`https://maps.google.com/?q=${this.props.delivery.storeAddress}`}
                    target="_blank">
                    map
                  </a>
                </td>
              </tr>

              <tr>
                <th>Address</th>
                <td>{this.props.delivery.deliveryAddress}</td>
                <td>
                  <a
                    href={`https://maps.google.com/?q=${this.props.delivery.deliveryAddress}`}
                    target="_blank">
                    map
                  </a>
                </td>
              </tr>

              {this.props.delivery.deliveryBusinessName ?
              <tr>
                <th>Business</th>
                <td>{this.props.delivery.deliveryBusinessName}</td>
              </tr> : <></>}

              <tr>
                <th>Name</th>
                <td>{this.props.delivery.name}</td>
                <td></td>
              </tr>

              <tr>
                <th>Identifier</th>
                <td>{this.props.delivery.identifier}</td>
                <td></td>
              </tr>

              <tr>
                <th>Phone</th>
                <td><a data-number={this.props.delivery.phone} data-order-id={this.props.delivery.orderId} data-action="click->phone-widget#call">{this.props.delivery.phone}</a></td>
                <td></td>
              </tr>

              {this.props.delivery.latestAssignmentTime ?
              <tr>
                <th>Allocated</th>
                <td>{this.props.delivery.latestAssignmentTime}</td>
                <td></td>
              </tr> : <></>}

              <tr>
                <th>Pickup time</th>
                <td>
                  <span>{this.props.delivery.pickupTime} </span>
                  {
                    this.props.delivery.pickupTime != this.props.delivery.originalPickupTime ?
                      <span style={{ textDecoration: "line-through" }}>{this.props.delivery.originalPickupTime}</span> :
                      null
                  }
                </td>
                <td></td>
              </tr>

              <tr>
                <th>Delivery time</th>
                <td>
                  <span>{this.props.delivery.deliveryTime} </span>
                  {
                    this.props.delivery.deliveryTime != this.props.delivery.originalDeliveryTime ?
                      <span style={{ textDecoration: "line-through" }}>{this.props.delivery.originalDeliveryTime}</span> :
                      null
                  }
                </td>
                <td></td>
              </tr>

              {this.props.deliveringNow ?
              <tr>
                <th>Live delivery time</th>
                <td colSpan={2}>{this.props.liveDropoffTime} {this.props.liveUpdateTimeAgo ?? <span><i className="fas fa-spinner fa-pulse"></i></span>}</td>
              </tr> : <></>}

              <tr>
                <th>Delivery inst.</th>
                <td>{this.props.delivery.deliveryInstructions}</td>
                <td></td>
              </tr>

              <tr>
                <td colSpan={3}>{items}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {delayPrep}
      </>
    );
  }
}

;
const SelectionNode = ({ quantity, description }: {
  quantity: string;
  description: string;
}): JSX.Element => {
  return (
    <div>
      <b>{quantity}:</b> {description}
    </div>
  )
};

const SelectionGroupNode = ({ description, selections }: {
  description: string;
  selections: Selection[];
}) => {
  const selectionNodes = selections.map(selection => {
    return <SelectionNode key={selection.id} quantity={selection.quantity} description={selection.description}/>
  });

  return (
    <div className="dash-option-set">
      <b>{description}:</b>

      <div>
        {selectionNodes}
      </div>
    </div>
  )
}

const MaplinkNode = ({ deliveries }: {
  deliveries: Delivery[];
}) => {
  // Use sets to remove duplicates, then convert back to an array for array functions
  const deliveryAddressesOrig: string[] = deliveries.map(delivery => delivery.deliveryAddress);
  const storeAddressesSet: Set<string> = new Set(deliveries.map(delivery => delivery.storeAddress));
  const deliveryAddressesSet: Set<string> = new Set(deliveryAddressesOrig);
  const storeAddresses: string[] = Array.from(storeAddressesSet);
  const deliveryAddresses: string[] = Array.from(deliveryAddressesSet);

  const sameAddressIndicator = deliveryAddressesOrig.length > deliveryAddresses.length ?
    <i className="fa fa-solid fa-star same-address-indicator" title="Multiple orders going to the same address"></i>
    : <></>;
  const origin: string = storeAddresses.shift() || "";
  const destination: string = deliveryAddresses.pop() || "";
  const waypoints: string = storeAddresses.concat(deliveryAddresses).join("|");

  const url: URL = new URL("/maps/dir/", "https://www.google.com");
  url.searchParams.set("origin", origin);
  url.searchParams.set("destination", destination);
  if (waypoints)
    url.searchParams.set("waypoints", waypoints);
  url.searchParams.set("travelmode", "driving");
  url.searchParams.set("api", "1");

  return (
    <div className="maplink">
      <a href={url.href} target="_blank" title="Open Google Map Directions" rel="noreferrer" onClick={e => e.stopPropagation()}>
        {sameAddressIndicator}
        <i className="fa fa-solid fa-map"></i>
      </a>
    </div>
  )
};

export { DeliveryComponent, MaplinkNode, CurrentTimeContext };
