import React, { useState, useEffect, createContext, useContext, useCallback } from "react";

import {
  Catalog,
  Section, Category, Product, SelectionGroup,
  OutOfStockList
} from "../../storefront/types/catalog";
import { parseCatalog, parseOutOfStockList } from "../../storefront/decoders";
import { classNames } from "src/common_util";
import { Temporal } from "@js-temporal/polyfill";
import { ReactComponent as HoursIcon } from "../../../images/icons/time-clock-circle.svg";

interface IConfigContext {
  outOfStockList: OutOfStockList;
  catalogUrl: string;
}

const ConfigContext = createContext<IConfigContext>({
  outOfStockList: new OutOfStockList({ loadedAt: Temporal.Now.instant(), outOfStockListItems: [] }),
  catalogUrl: "",
});

const Viewer = ({
  catalogUrl,
  outOfStockListUrl,
  editorUrl,
}: {
  catalogUrl?: string;
  outOfStockListUrl?: string;
  editorUrl?: string;
}) => {
  const [catalog, setCatalog] = useState<Catalog | undefined>();
  const [outOfStockList, setOutOfStockList] = useState<OutOfStockList | undefined>();
  const [fetchingCatalog, setFetchingCatalog] = useState<boolean>(true);
  const [fetchingOutOfStockList, setFetchingOutOfStockList] = useState<boolean>(true);

  useEffect(() => {
    if (catalogUrl) {
      fetch(catalogUrl).then(async resp => {
        const json = await resp.json();
        const catalog = parseCatalog(json);
        setCatalog(catalog);
      }).finally(() => setFetchingCatalog(false));
    } else {
      setFetchingCatalog(false);
    }

    if (outOfStockListUrl && outOfStockListUrl.length > 0) {
      fetch(outOfStockListUrl).then(async resp => {
        setOutOfStockList(parseOutOfStockList(Temporal.Now.instant(), await resp.json()));
      }).finally(() => setFetchingOutOfStockList(false));
    } else {
      setOutOfStockList(new OutOfStockList({ loadedAt: Temporal.Now.instant(), outOfStockListItems: [] }));
      setFetchingOutOfStockList(false);
    }
  }, [catalogUrl, outOfStockListUrl]);

  if (fetchingCatalog || fetchingOutOfStockList) {
    return <></>;
  }

  if (catalog && outOfStockList) {
    return (
      <ViewerInner
        catalogUrl={catalogUrl ?? ""}
        catalog={catalog}
        outOfStockList={outOfStockList}
        editorUrl={editorUrl}
      />
    );
  }

  return (
    <LaunchEditorButton buttonText={"Catalog editor"} editorUrl={editorUrl} />
  );
}

const ViewerInner = ({
  catalogUrl,
  catalog,
  outOfStockList,
  editorUrl,
}: {
  catalogUrl: string;
  catalog: Catalog;
  outOfStockList: OutOfStockList;
  editorUrl?: string;
}) => {
  const sections = catalog.sections.map(section => {
    return <CatalogSection section={section} key={section.key} />
  });

  return (
    <ConfigContext.Provider value={{ catalogUrl: catalogUrl, outOfStockList: outOfStockList }}>
      <div className="">
        <LaunchEditorButton buttonText={"Edit"} editorUrl={editorUrl} />
        {sections}
      </div>
    </ConfigContext.Provider>
  );
}

const LaunchEditorButton = ({ buttonText, editorUrl }: { buttonText: string; editorUrl?: string } ) => {
  if (!editorUrl) return <></>;

  return (
    <a href={editorUrl} data-turbo="false" data-turbo-frame="_top" className="button is-pulled-right">
      <span className="icon">
        <i className="fas fa-pencil"></i>
      </span>
      <span>
        {buttonText}
      </span>
    </a>
  );
}

const ToggleButton = ({
  expanded,
  onToggle,
}: {
  expanded: boolean;
  onToggle: () => void;
}) => {
  const toggle = () => {
    onToggle();
  };

  const iconClasses = classNames({
    "fas": true,
    "fa-chevron-down": expanded,
    "fa-chevron-right": !expanded,
  });

  return (
    <button className="button is-white" onClick={toggle}>
      <span className="icon">
        <i className={iconClasses}></i>
      </span>
    </button>
  );
}

const CatalogSection = ({
  section
}: {
  section: Section;
}) => {
  const [expanded, setExpanded] = useState(true);

  const toggle = () => {
    setExpanded(!expanded);
  }

  const sectionHoursDescription = section.availabilities.map(avail => {
    return `${avail.dayOfWeek()} ${avail.startTime.toLocaleString("en-NZ", { hour: "2-digit", minute: "2-digit" })} to ${avail.endTime.toLocaleString("en-NZ", { hour: "2-digit", minute: "2-digit" })}`;
  }).join("\n");

  const categories = expanded && section.categories.map(category => {
    return <CatalogCategory category={category} key={category.key} />
  });

  return (
    <>
      <div className="block is-flex is-align-items-center">
        <ToggleButton expanded={expanded} onToggle={toggle} />
        <span className="ml-4 title mb-0 mr-2">{section.title}</span>
        <span className="block is-flex is-align-items-center has-tooltip-arrow" style={{ "fontFamily": "monospace" }} data-tooltip={sectionHoursDescription}><HoursIcon className="icon streamline-icon"></HoursIcon></span>
      </div>

      {categories}
    </>
  );
}

const CatalogCategory = ({
  category
}: {
  category: Category;
}) => {
  const [expanded, setExpanded] = useState(true);

  const toggle = () => {
    setExpanded(!expanded);
  }

  const children = expanded && (() => {
    const nodes = () => {
      if (category.kind === "leaf") {
        return category.products.map(product => {
          return <CatalogProduct
            product={product}
            key={product.key}
          />
        });
      }
      
      return category.categories.map(category => {
        return <CatalogCategory
          category={category}
          key={category.key}
        />
      });
    }
      return (
      <>
    <hr />
    {nodes()}
    </>
    );
  })();

  return (
    <div className="box">
      <div className="is-flex is-align-items-center">
        <ToggleButton expanded={expanded} onToggle={toggle} />
        <span className="ml-4 title is-size-5">{category.title}</span>
      </div>

      {children}
    </div>
  );
}

const CatalogProduct = ({
  product,
}: {
  product: Product;
}) => {
  const config = useContext(ConfigContext);
  const isOutOfStock = config.outOfStockList.isProductInOutage(product);

  const formatter = new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" });
  const outOfStockBadge = isOutOfStock && (
    <span className="tag is-dark is-rounded">Out of stock</span>
  );

  const image = product.images.length > 0 && (
    <img loading="lazy" src={imageUrl(config.catalogUrl, product.images[0])} />
  );

  const productChildren = () => {
    if (product.kind === "leaf") {
      return [];
    }

    return product.selectionGroups.map(sg => <CatalogSelectionGroup key={sg.key} group={sg} />)
  }

  return (
    <div className="media">
      <div className="media-left">
        <div className="image is-64x64">
          {image}
        </div>
      </div>

      <div className="media-content">
        <div className="content">
          <h1 className="title is-size-6 mb-2">{product.title}</h1>
          {product.description}
        </div>

        {productChildren()}
      </div>

      <div className="media-right">
        {outOfStockBadge}
        <b>{formatter.format(product.price / 100.0)}</b>
      </div>
    </div>
  );
}

const CatalogSelectionGroup = ({
  group
}: {
  group: SelectionGroup;
}) => {
  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded]);
  const iconClasses = classNames({
    "fas": true,
    "fa-chevron-down": expanded,
    "fa-chevron-right": !expanded,
  });

  const groupChildren = group.products.map(p => <CatalogProduct key={p.key} product={p} />);

  return (
    <div className="pl-4 mt-4" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.05)" }}>
      <h3 className="title is-size-6" onClick={toggleExpanded}>
      <span className="icon"><i className={iconClasses} /></span>
        {group.title}
      </h3>

      
      {expanded && group.description}

      {expanded && groupChildren}
    </div>
  );
}

const imageUrl = (catalogUrl: string, primary: string): string => {
  const url = new URL(`/product_images/${primary}`, new URL(catalogUrl, window.location.href));
  url.searchParams.set("variant", "webp_2x");
  return url.toString();
}

export { Viewer };
